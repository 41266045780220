@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  overflow-x: hidden;
}

#root > div {
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

*:focus {
  outline: none;
}
